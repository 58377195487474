import { paymentTabStatusTypes } from "Constants";

export const handleAddActiveDTPaymentTab = (
    postPonedOrder: any,
    addActivePaymentTab: Function,
    activeOrderWindowId: string
) => {
    return addActivePaymentTab(activeOrderWindowId, null, paymentTabStatusTypes.DT_CREATED, true, {
        orderNumber: postPonedOrder.orderNo,
        orderId: postPonedOrder.id
    });
};
