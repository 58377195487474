import { getActivePaymentTabTexts } from "../../paymentHelpers";
import { paymentTabStatusTypes } from "Constants";
import { handleCreatePosOrder } from "../handleCreatePosOrder";
import { posStore } from "Stores";

export const makePinByPassPayment = async (
    allowPinByPass: any,
    orderInput: any,
    paymentFuncs: any,
    updateActivePaymentTabStatus: any,
    terminalId: any,
    paymentMethodAmount: any,
    activeOrderWindowId: any,
    dtOrderId: any
) => {
    const { queueHasStarted, updateQueuedOrdersList } = posStore.getState();

    const { data } = await handleCreatePosOrder(
        terminalId,
        paymentMethodAmount,
        { ...orderInput, allowPinByPass },
        paymentFuncs,
        dtOrderId
    );

    const { terminalResponse, order } = dtOrderId ? data.payPostponedPaymentOrder : data.purchasePosOrder;
    const displayTexts = getActivePaymentTabTexts(order, terminalResponse);

    updateActivePaymentTabStatus(
        activeOrderWindowId,
        terminalResponse.operationSuccess,
        displayTexts.headerText,
        displayTexts.displayText,
        terminalResponse.operationSuccess ? paymentTabStatusTypes.APPROVED : paymentTabStatusTypes.FAILED
    );

    if (queueHasStarted) {
        updateQueuedOrdersList(order.id, order.orderNo, order.puckNo ?? "");
    }

    return order;
};
