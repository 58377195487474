import { envConstants, frontyEnv } from "Constants";
import { heartBeatMessage } from "TempUtils";

export const pingReceiptPrinter = async (receiptPrinter: any) => {
    if (envConstants.PROFILE === frontyEnv.DEV) {
        return true;
    } else {
        return await heartBeatMessage(receiptPrinter.ip, receiptPrinter.deviceName);
    }
};
