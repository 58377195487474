import { handleAddActiveDTPaymentTab } from "./createDTOrderHelpers/handleAddActiveDTPaymentTab";

export const createDTOrder = async (
    orderInput: any,
    createPostponedPaymentOrder: any,
    addCartToDTPreviousCart: any,
    addActivePaymentTab: any,
    activeOrderWindowId: string
) => {
    try {
        const {
            data: { postponePaymentOrder }
        } = await createPostponedPaymentOrder({
            variables: {
                posOrderInput: {
                    ...orderInput
                }
            }
        });
        addCartToDTPreviousCart();
        handleAddActiveDTPaymentTab(postponePaymentOrder.order, addActivePaymentTab, activeOrderWindowId);
        return postponePaymentOrder.order;
    } catch (err) {
        console.log("ERROR WHEN CREATING DT ORDER", err);
        return null;
    }
};
