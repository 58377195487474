import { paymentTabStatusTypes } from "Constants";
import { handleCreatePosOrder } from "./handleCreatePosOrder";
import PosInvoiceModal from "../../components/PosInvoiceModal/PosInvoiceModal";
import { handleFailedOrder } from "./handleFailedOrder";
import { getActivePaymentTabTexts } from "../paymentHelpers";
import { posStore } from "Stores";
import { ConfirmationOptions } from "Providers";
import { CommonPOSPaymentParams } from "Types";

type InvoicePaymentParams = {
    awaitConfirm: (options: ConfirmationOptions) => Promise<void>;
} & CommonPOSPaymentParams;

export const makeInvoicePayment = async ({
    paymentMethodAmount,
    orderInput,
    updateActivePaymentTabStatus,
    activeOrderWindowId,
    paymentFuncs,
    postponedInfo,
    awaitConfirm
}: InvoicePaymentParams) => {
    const { queueHasStarted, updateQueuedOrdersList } = posStore.getState();

    // TODO Rename value and type it - look into this next time!!!
    const { value }: any = await awaitConfirm({
        component: PosInvoiceModal,
        catchOnCancel: true,
        modalContent: {
            orderInput,
            activeOrderWindowId,
            updateActivePaymentTabStatus
        }
    });

    if (value) {
        try {
            const { invoiceComment, ...orderInvoice } = value;
            const orderInputWithInvoice = {
                ...orderInput,
                comment: invoiceComment,
                invoiceData: orderInvoice
            };

            const { data } = await handleCreatePosOrder(
                null,
                paymentMethodAmount,
                orderInputWithInvoice,
                paymentFuncs,
                postponedInfo
            );

            const { order } =
                orderInput.driveThrough || orderInput.postponePayment
                    ? data.payPostponedPaymentOrder
                    : data.purchasePosOrder;

            if (order.failedReason) {
                handleFailedOrder(activeOrderWindowId, order, updateActivePaymentTabStatus);
                return null;
            } else {
                const displayTexts = getActivePaymentTabTexts(order);
                updateActivePaymentTabStatus(
                    activeOrderWindowId,
                    true,
                    displayTexts.headerText,
                    displayTexts.displayText + `\nFakturaköp\nUnderskrift krävs på kvittot`,
                    paymentTabStatusTypes.APPROVED
                );

                if (queueHasStarted) {
                    updateQueuedOrdersList(order.id, order.orderNo, order.puckNo ?? "");
                }

                return order;
            }
        } catch (error) {
            console.error("Error in makeInvoicePayment", error);
        }
    } else {
        // Cashier cancelled purchase
        return null;
    }
};
