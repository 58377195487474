import { OrderInput } from "Types";

export type PostponedInfo = {
    orderId: string;
    postponePayment: boolean;
};

export const makePostponeExtendOrder = async (
    orderInput: OrderInput,
    postponedInfo: PostponedInfo,
    terminalId: string | undefined,
    extendPostponedPaymentOrder: any
) => {
    try {
        const {
            data: { extendPostponedOrder }
        } = await extendPostponedPaymentOrder({
            variables: {
                orderId: postponedInfo.orderId,
                posOrderInput: {
                    ...orderInput,
                    terminalId
                }
            }
        });

        return extendPostponedOrder?.order;
    } catch (err) {
        console.log("ERROR WHEN EXTENDING POSTPONE ORDER", err);
        return null;
    }
};
