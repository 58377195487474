import { Order, OrderWindow, OrderWindowContactInformation, PosOrderDTOInput } from "Types";

export const makePostPonedOrder = async (
    orderInput: PosOrderDTOInput,
    createPostponedPaymentOrder: Function,
    activeOrderWindow: OrderWindow,
    contactInfo?: any
): Promise<Order | null> => {
    try {
        let orderInputWithInfo = {
            ...orderInput,
            comment: contactInfo?.comment,
            pickupTime: contactInfo?.pickupTime,
            userInformation: {
                onlineContactInformation: {
                    name: contactInfo?.name,
                    phoneNumber: contactInfo?.phoneNumber
                }
            }
        };

        // Save fields on OrderWindow
        const orderWindow: OrderWindow = {
            ...activeOrderWindow,
            contactInformation: {
                name: contactInfo?.name,
                phoneNumber: contactInfo?.phoneNumber,
                comment: contactInfo?.comment,
                pickupTime: contactInfo?.pickupTime
            },
            previousCartProducts: activeOrderWindow?.cartProducts
        };

        const {
            data: { postponePaymentOrder }
        } = await createPostponedPaymentOrder({
            variables: {
                posOrderInput: orderInputWithInfo,
                orderWindow: orderWindow
            }
        });

        return postponePaymentOrder.order;
    } catch (err) {
        console.log("ERROR WHEN CREATING DT ORDER", err);
        return null;
    }
};
