export const getBundleCategoryColor = (bundleCategoryColors, bundleCategoryId) => {
    const catColor = bundleCategoryColors.find(
        ({ bundleProductCategoryId }) => bundleProductCategoryId === bundleCategoryId
    );
    return catColor ? catColor.color : null;
};

export const scrollToCategory = categoryId => {
    const category = document.getElementById(categoryId);
    if (category) {
        category.scrollIntoView({ behavior: "smooth" });
    }
};
