import { paymentTabStatusTypes } from "Constants";
import { CalculateCashChangeModal } from "../../components";
import { handleCreatePosOrder, handleFailedOrder } from "./";
import { getActivePaymentTabTexts } from "../paymentHelpers";
import { posStore, qoplaStore } from "Stores";
import { ConfirmationOptions } from "Providers";
import { handleReceiptPrint } from "../posHelpers";
import { CashMoneyChangeInput } from "../../../../pos-admin/utils/posTypes";
import { CommonPOSPaymentParams } from "Types";

type CashPaymentParams = {
    dtOrderId: string;
    awaitConfirm: (options: ConfirmationOptions) => Promise<CashMoneyChangeInput>;
} & CommonPOSPaymentParams;

export const makeCashPayment = async ({
    paymentMethodAmount,
    orderInput,
    updateActivePaymentTabStatus,
    activeOrderWindowId,
    paymentFuncs,
    postponedInfo,
    awaitConfirm,
    optionalReceipts
}: CashPaymentParams) => {
    const { queueHasStarted, updateQueuedOrdersList, selectedPos } = posStore.getState();
    const { selectedShop } = qoplaStore.getState();

    const cashChange: CashMoneyChangeInput = await awaitConfirm({
        component: CalculateCashChangeModal,
        catchOnCancel: true,
        modalContent: {
            paymentMethodAmount
        }
    });

    if (cashChange?.value) {
        const changeAmount = cashChange?.value?.changeAmount;

        try {
            // Create order
            const { data } = await handleCreatePosOrder(
                null,
                paymentMethodAmount,
                orderInput,
                paymentFuncs,
                postponedInfo
            );

            const { order } =
                orderInput.driveThrough || orderInput.postponePayment
                    ? data.payPostponedPaymentOrder
                    : data.purchasePosOrder;
            if (order.failedReason) {
                handleFailedOrder(activeOrderWindowId, order, updateActivePaymentTabStatus);
                return null;
            } else {
                // Successful order
                const displayTexts = getActivePaymentTabTexts(order);
                updateActivePaymentTabStatus(
                    activeOrderWindowId,
                    true,
                    displayTexts.headerText,
                    displayTexts.displayText + `\n Växel: ${changeAmount} kr`,
                    paymentTabStatusTypes.APPROVED
                );

                await handleReceiptPrint(order, selectedShop!, optionalReceipts, selectedPos!, changeAmount);

                if (queueHasStarted) {
                    updateQueuedOrdersList(order.id, order.orderNo, order.puckNo ?? "");
                }

                return order;
            }
        } catch (error) {
            console.error("Error in makeCashPayment", error);
        }
    } else {
        // Cashier cancelled purchase
        return null;
    }
};
