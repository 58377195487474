import { handleCreatePosOrder, handleFailedOrder } from "./";
import { confirmNotification } from "TempUtils";
import { cancelOrderByCashier } from "../activePaymentHelpers";
import { paymentTabStatusTypes } from "Constants";
import { getActivePaymentTabTexts } from "../paymentHelpers";
import { posStore } from "Stores";
import { CommonPOSPaymentParams } from "Types";

export const makeSwishPayment = async ({
    paymentMethodAmount,
    orderInput,
    updateActivePaymentTabStatus,
    activeOrderWindowId,
    paymentFuncs,
    postponedInfo
}: CommonPOSPaymentParams) => {
    const { queueHasStarted, updateQueuedOrdersList } = posStore.getState();
    try {
        const { value } = await confirmNotification(
            "Bekräftelse av mottagen Swishbetalning",
            "",
            "Mottagen",
            "#89CB9C"
        );
        if (value === undefined) {
            return cancelOrderByCashier(updateActivePaymentTabStatus, activeOrderWindowId);
        }

        const { data } = await handleCreatePosOrder(null, paymentMethodAmount, orderInput, paymentFuncs, postponedInfo);
        const { order } =
            orderInput.driveThrough || (postponedInfo && postponedInfo.postponePayment)
                ? data.payPostponedPaymentOrder
                : data.purchasePosOrder;

        if (order.failedReason) {
            handleFailedOrder(activeOrderWindowId, order, updateActivePaymentTabStatus);
            return null;
        } else {
            // Successful order
            const displayTexts = getActivePaymentTabTexts(order);
            updateActivePaymentTabStatus(
                activeOrderWindowId,
                true,
                displayTexts.headerText,
                displayTexts.displayText,
                paymentTabStatusTypes.APPROVED
            );
            if (queueHasStarted) {
                updateQueuedOrdersList(order.id, order.orderNo, order.puckNo ?? "");
            }

            return order;
        }
    } catch (error) {
        console.log("ERROR IN MAKESWISHPAYMENT", error);
    }
};
