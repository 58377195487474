import { getActivePaymentTabTexts } from "../paymentHelpers";
import { paymentTabStatusTypes, POS_PRINTER_NOT_REACHABLE_HEADER, POS_PRINTER_NOT_REACHABLE_TEXT } from "Constants";
import { pingReceiptPrinter, handleCreatePosOrder, handleFailedOrder } from "./";
import { posStore } from "Stores";
import { promptToFailWithoutPrinter } from "..";
import { CommonPOSPaymentParams } from "Types";

type CardPaymentParams = { terminal: any; manualCardPayment: boolean } & CommonPOSPaymentParams;

export const makeManualCardPayment = async ({
    paymentMethodAmount,
    orderInput,
    updateActivePaymentTabStatus,
    terminal,
    activeOrderWindowId,
    paymentFuncs,
    receiptPrinter,
    postponedInfo
}: CardPaymentParams) => {
    const { queueHasStarted, updateQueuedOrdersList } = posStore.getState();
    try {
        const updatedOrderInput = {
            ...orderInput,
            manualCardPayment: true
        };

        const isPrinterAvailable = await pingReceiptPrinter(receiptPrinter);

        const shouldFailWithoutPrinter = !isPrinterAvailable ? await promptToFailWithoutPrinter() : false;
        if (shouldFailWithoutPrinter) {
            updateActivePaymentTabStatus(
                activeOrderWindowId,
                false,
                POS_PRINTER_NOT_REACHABLE_HEADER,
                POS_PRINTER_NOT_REACHABLE_TEXT,
                paymentTabStatusTypes.FAILED
            );
            return;
        }

        const { data } = await handleCreatePosOrder(
            terminal,
            paymentMethodAmount,
            updatedOrderInput,
            paymentFuncs,
            postponedInfo
        );

        const purchaseData =
            orderInput.driveThrough || orderInput.postponePayment
                ? data.payPostponedPaymentOrder
                : data.purchasePosOrder;

        if (purchaseData) {
            const { order, terminalResponse } = purchaseData;

            const displayTexts = getActivePaymentTabTexts(order, terminalResponse);
            if (order.failedReason) {
                handleFailedOrder(activeOrderWindowId, order, updateActivePaymentTabStatus);
                return null;
            } else {
                updateActivePaymentTabStatus(
                    activeOrderWindowId,
                    true,
                    displayTexts.headerText,
                    displayTexts.displayText,
                    paymentTabStatusTypes.APPROVED
                );

                if (queueHasStarted) {
                    updateQueuedOrdersList(order.id, order.orderNo, order.puckNo ?? "");
                }

                return order;
            }
        }
    } catch (error) {
        console.log("ERROR IN MAKEMANUALCARDPAYMENT", error);
    }
};
