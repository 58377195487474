import { confirmOrCancelNotification } from "TempUtils";

export const openPinByPass = async () => {
    return await confirmOrCancelNotification(
        "Bekräftelse av signaturbetalning",
        "Glöm inte att kontrollera id kort.",
        "Bekräfta",
        "#89CB9C",
        "Tillåt inte"
    );
};
