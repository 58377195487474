import { posStore } from "Stores";

export const handleCreatePosOrder = async (
    terminal: { terminalId: string } | null = null,
    paymentMethodAmount: any,
    orderInput: any,
    paymentFuncs: any,
    postponedInfo: any
) => {
    const { queueHasStarted } = posStore.getState();
    const { payPostponedPaymentOrder, createPosOrder } = paymentFuncs;

    let terminalId = terminal ? terminal.terminalId : null;
    if (orderInput.manualCardPayment) {
        terminalId = null;
    }

    const posOrderInput = {
        ...orderInput,
        terminalId,
        paymentMethodAmounts: [paymentMethodAmount],
        postponePayment: postponedInfo ? postponedInfo.postponePayment : null,
        queueOrder: queueHasStarted
    };

    const makePosOrder = async () => {
        if (orderInput.driveThrough || !!postponedInfo) {
            return await payPostponedPaymentOrder({
                variables: {
                    orderId: postponedInfo.orderId,
                    posOrderInput
                }
            });
        } else {
            return await createPosOrder({
                variables: {
                    posOrderInput
                },
                errorPolicy: "ignore"
            });
        }
    };

    return await makePosOrder();
};
