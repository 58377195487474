import { paymentTabsHeaderTexts, paymentTabStatusTypes, errorConstants } from "Constants";

const { PENDING, APPROVED, FAILED, DT_CREATED, POSTPONED_ONGOING, POSTPONED_CREATED, POSTPONED_EXTENDED } =
    paymentTabStatusTypes;

export const cancelOrderByCashier = (updateActivePaymentTabStatus, orderWindowId) => {
    return updateActivePaymentTabStatus(
        orderWindowId,
        false,
        paymentTabsHeaderTexts.CANCELLED,
        "Avbrutet av kassör",
        paymentTabStatusTypes.CANCELLED
    );
};

export const getActivePaymentTabIcon = (status, isCashInvoice, size) => {
    switch (status) {
        case PENDING:
            return { name: "circle notch", loading: true, color: "blue", size };
        case APPROVED:
        case DT_CREATED:
        case POSTPONED_CREATED:
        case POSTPONED_ONGOING:
        case POSTPONED_EXTENDED:
            return isCashInvoice
                ? { name: "exclamation circle", color: "blue", size }
                : { name: "checkmark", color: "green", size };
        case paymentTabStatusTypes.CANCELLED:
        case FAILED:
            return { name: "close", color: "red", size };

        default:
            return;
    }
};

export const isCashInvoice = paymentTabDisplayText =>
    paymentTabDisplayText && paymentTabDisplayText.includes(errorConstants.CASH_INVOICE_V2);

export const isAlreadyPaid = text => text && text.includes(errorConstants.ALREADY_PAID);
