import swal from "sweetalert2";

export const promptToFailWithoutPrinter = async () => {
    const { value: canProceedWithoutPrinter } = await swal({
        title: "Skrivare otillgänglig",
        text: "Vill kunden genomföra köpet utan kvitto?",
        confirmButtonText: "Ja",
        confirmButtonColor: "#48BB78",
        cancelButtonText: "Nej, avbryt köp",
        cancelButtonColor: "#F56565",
        type: "warning",
        showCancelButton: true,
        heightAuto: false
    });

    return !canProceedWithoutPrinter;
};
