import { paymentTabStatusTypes } from "Constants";
import { FailedOrderReason } from "Types";
import { ORDER_ALREADY_PAID } from "../paymentHelpers";

export const handleFailedOrder = (activeOrderWindowId: string, order: any, updateActivePaymentTabStatus: Function) => {
    try {
        if (order.failedReason == FailedOrderReason.OUT_OF_STOCK) {
            // const headerText = translate("outOfStock"); // TODO get translate in here
            const headerText = "Slutsåld";

            // const displayText = translate("outOfStockExplanation");// TODO get translate in here
            const displayText = "Minst en av produkterna i ordern är slutsålda"; // TODO get translate in here

            updateActivePaymentTabStatus(
                activeOrderWindowId,
                true,
                headerText,
                displayText,
                paymentTabStatusTypes.FAILED
            );
        } else if ((order.driveThrough || order.postponePayment) && order.failedReason === ORDER_ALREADY_PAID) {
            // Legacy behavior since we don't have ORDER_ALREADY_PAID In the enum
            const headerText = order.failedReason;
            const displayText = "Order är redan betald, stäng ner denna nota";
            updateActivePaymentTabStatus(
                activeOrderWindowId,
                true,
                headerText,
                displayText,
                paymentTabStatusTypes.FAILED
            );
        } else {
            throw "Unknown failure";
        }
    } catch (e) {
        // Backup failed message. Should not happen.
        const headerText = order?.failedReason || "Fel";
        const displayText = "Något har gått snett. Försök igen.";
        updateActivePaymentTabStatus(activeOrderWindowId, true, headerText, displayText, paymentTabStatusTypes.FAILED);
    }
};
